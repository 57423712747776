import { render, staticRenderFns } from "./EmpresaCategorias.vue?vue&type=template&id=74622f0a&scoped=true&"
import script from "./EmpresaCategorias.vue?vue&type=script&lang=js&"
export * from "./EmpresaCategorias.vue?vue&type=script&lang=js&"
import style0 from "./EmpresaCategorias.vue?vue&type=style&index=0&id=74622f0a&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "74622f0a",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VHover } from 'vuetify/lib/components/VHover';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItem } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';
import { VTabsSlider } from 'vuetify/lib/components/VTabs';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
installComponents(component, {VCard,VCardActions,VCardText,VCol,VContainer,VDialog,VHover,VIcon,VListItem,VRow,VTab,VTabItem,VTabs,VTabsItems,VTabsSlider,VToolbar})
