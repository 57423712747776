var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"empresa-contabilidad-container"},[_c('v-container',[_c('v-row',{attrs:{"align":"center","justify":"space-between"}},[_c('v-col',{attrs:{"cols":"12","md":"8"}},[_c('h1',[_vm._v("Categorías")]),_c('p',[_vm._v("Administra y consulta las categorías de la empresa.")])])],1),_c('v-card',[_c('v-toolbar',{attrs:{"dark":"","flat":""},scopedSlots:_vm._u([{key:"extension",fn:function(){return [_c('v-tabs',{attrs:{"align-with-title":""},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tabs-slider',{attrs:{"color":"yellow"}}),_vm._l((_vm.items),function(item){return _c('v-tab',{key:item},[_vm._v(" "+_vm._s(item)+" ")])})],2)]},proxy:true}])}),_c('v-tabs-items',{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab-item',[_c('v-card',{attrs:{"flat":""}},[_c('v-card-text',[(_vm.categoriaPadre)?_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-dialog',{attrs:{"width":"500"},model:{value:(_vm.dialogCategoria),callback:function ($$v) {_vm.dialogCategoria=$$v},expression:"dialogCategoria"}},[_c('producto-categoria-create',{attrs:{"parent":_vm.categoriaPadre,"dialogCategoria":_vm.dialogCategoria},on:{"refresh-categorias":_vm.fetchCategoriaPadre,"close-dialog":function($event){_vm.dialogCategoria = false}}})],1),(_vm.categorias && _vm.categorias.length > 0)?_c('v-row',{attrs:{"no-gutters":"","align":"center","justify":"start"}},_vm._l((_vm.categorias),function(child){return _c('v-col',{key:child.id,attrs:{"cols":"4"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"ma-2 pa-6 d-flex align-center position-relative",attrs:{"outlined":"","hover":"","height":"100","tile":""},on:{"click":function($event){return _vm.$router.push({
                              name: 'ProductoCategoriasTree',
                              params: { padre: child.name },
                            })},"contextmenu":function($event){return _vm.openMenu(child, $event)}}},[_c('v-card-text',[_c('div',{class:hover
                                  ? 'title white--text text-center'
                                  : 'title text-center'},[_vm._v(" "+_vm._s(child.name)+" ")])]),_c('v-card-actions',[_c('v-list-item',{staticClass:"grow"},[_c('v-row',{attrs:{"align":"center","justify":"end"}},[(child.es_compartida)?_c('v-icon',{staticClass:"mr-1"},[_vm._v(" mdi-share-variant ")]):_vm._e()],1)],1)],1)],1)]}}],null,true)})],1)}),1):_c('v-row',{staticStyle:{"height":"250px"},attrs:{"align":"center","justify":"center"}},[_c('v-col',{attrs:{"cols":""}},[_c('h1',[_vm._v("Bueno, empecemos registrando la primer categoría")])]),_c('v-col',{attrs:{"cols":"4"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                  var hover = ref.hover;
return [_c('v-card',{staticClass:"mx-auto",attrs:{"elevation":hover ? 12 : 2},on:{"click":function($event){_vm.dialogCategoria = !_vm.dialogCategoria}}},[_c('v-container',[_c('v-card-text',[_c('h1',{class:hover ? 'amber--text text--accent-3' : ''},[_c('v-icon',{attrs:{"large":""}},[_vm._v("mdi-plus")]),_vm._v("Nueva Categoría ")],1)])],1)],1)]}}],null,false,932835205)})],1)],1)],1)],1):_vm._e()],1)],1)],1),_c('v-tab-item',[_c('v-card',{attrs:{"flat":""}},[_c('v-card-text',[_c('v-row',_vm._l((_vm.sucursales),function(sucursal){return _c('v-col',{key:sucursal,attrs:{"cols":"12","md":"4"}},[_c('v-card',{attrs:{"outlined":""}},[_c('v-card-text',{staticClass:"text-center"},[_c('h3',[_vm._v(_vm._s(sucursal))])])],1)],1)}),1)],1)],1)],1),_c('v-tab-item',[_c('v-card',{attrs:{"flat":""}},[_c('v-card-text',{domProps:{"textContent":_vm._s(_vm.text)}})],1)],1),_c('v-tab-item',[_c('v-card',{attrs:{"flat":""}},[_c('v-card-text',{domProps:{"textContent":_vm._s(_vm.text)}})],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }