<template>
    <div class="empresa-contabilidad-container">
      <v-container>
        <v-row align="center" justify="space-between">
          <v-col cols="12" md="8">
            <h1>Categorías</h1>
            <p>Administra y consulta las categorías de la empresa.</p>
          </v-col>
        </v-row>
        <v-card>
          <v-toolbar  dark flat>
           
           
            <template v-slot:extension>
              <v-tabs v-model="tab" align-with-title>
                <v-tabs-slider color="yellow"></v-tabs-slider>
                <v-tab
                  v-for="item in items"
                  :key="item"
                >
                  {{ item }}
                </v-tab>
              </v-tabs>
            </template>
          </v-toolbar>
  
          <v-tabs-items v-model="tab">
            <!-- Pestaña: Todas las categorías -->
            <v-tab-item>
              <v-card flat>
                <v-card-text>
                  <v-row v-if="categoriaPadre">
                    <v-col cols="12">
                      <v-dialog v-model="dialogCategoria" width="500">
                        <producto-categoria-create
                          :parent="categoriaPadre"
                          @refresh-categorias="fetchCategoriaPadre"
                          @close-dialog="dialogCategoria = false"
                          :dialogCategoria="dialogCategoria"
                        ></producto-categoria-create>
                      </v-dialog>
  
                      <v-row
                        no-gutters
                        v-if="categorias && categorias.length > 0"
                        align="center"
                        justify="start"
                      >
                        <v-col cols="4" v-for="child in categorias" :key="child.id">
                          <v-hover v-slot:default="{ hover }">
                            <v-card
                              outlined
                              hover
                              @click="
                                $router.push({
                                  name: 'ProductoCategoriasTree',
                                  params: { padre: child.name },
                                })
                              "
                              class="ma-2 pa-6 d-flex align-center position-relative"
                              height="100"
                              tile
                              @click.right="openMenu(child, $event)"
                            >
                              <v-card-text>
                                <div
                                  :class="
                                    hover
                                      ? 'title white--text text-center'
                                      : 'title text-center'
                                  "
                                >
                                  {{ child.name }}
                                </div>
                              </v-card-text>
  
                              <v-card-actions>
                                <v-list-item class="grow">
                                  <v-row align="center" justify="end">
                                    <v-icon class="mr-1" v-if="child.es_compartida">
                                      mdi-share-variant
                                    </v-icon>
                                  </v-row>
                                </v-list-item>
                              </v-card-actions>
                            </v-card>
                          </v-hover>
                        </v-col>
                      </v-row>
  
                      <v-row
                        v-else
                        align="center"
                        justify="center"
                        style="height: 250px"
                      >
                        <v-col cols>
                          <h1>Bueno, empecemos registrando la primer categoría</h1>
                        </v-col>
                        <v-col cols="4">
                          <v-hover v-slot:default="{ hover }">
                            <v-card
                              @click="dialogCategoria = !dialogCategoria"
                              :elevation="hover ? 12 : 2"
                              class="mx-auto"
                            >
                              <v-container>
                                <v-card-text>
                                  <h1
                                    :class="hover ? 'amber--text text--accent-3' : ''"
                                  >
                                    <v-icon large>mdi-plus</v-icon>Nueva Categoría
                                  </h1>
                                </v-card-text>
                              </v-container>
                            </v-card>
                          </v-hover>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-tab-item>
  
            <!-- Pestaña: Sucursales -->
            <v-tab-item>
              <v-card flat>
                <v-card-text>
                  <v-row>
                    <v-col cols="12" md="4" v-for="sucursal in sucursales" :key="sucursal">
                      <v-card outlined>
                        <v-card-text class="text-center">
                          <h3>{{ sucursal }}</h3>
                        </v-card-text>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-tab-item>
  
            <!-- Otras pestañas (puedes agregar más contenido) -->
            <v-tab-item>
              <v-card flat>
                <v-card-text v-text="text"></v-card-text>
              </v-card>
            </v-tab-item>
            <v-tab-item>
              <v-card flat>
                <v-card-text v-text="text"></v-card-text>
              </v-card>
            </v-tab-item>
          </v-tabs-items>
        </v-card>
      </v-container>
    </div>
  </template>
  
  <script>
  import { mapActions, mapState } from "vuex";
  import ProductoCategoriaCreate from "@/components/producto/categorias/ProductoCategoriaCreate";
  import CategoriasAPI from "@/api/categorias";
  
  export default {
    name: "EmpresaCategorias",
    components: { ProductoCategoriaCreate },
    props: {
      empresa: {
        type: Object,
        required: true,
      },
    },
    data() {
      return {
        isLoading: false, // Indicador de carga
        categoriaPadre: [],
        categorias: [],
        selectEditCategoria: null,
        menu: false,
        xMenu: 0,
        yMenu: 0,
        tab: null,
        items: ['Todas las categorías', 'Sucursales', 'shopping', 'videos', 'images', 'news'],
        sucursales: ['Sucursal 1', 'Sucursal 2', 'Sucursal 3'], // Hardcodeado de 3 nombres de sucursales
        text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
      };
    },
    methods: {
      fetchCategoriaPadre() {
        CategoriasAPI.index().then((response) => {
          this.categoriaPadre = response.data.parent;
          this.categorias = response.data.parent.children;
        });
      },
      openMenu(categoria, e) {
        this.selectEditCategoria = { ...categoria };
        e.preventDefault();
        this.xMenu = e.pageX;
        this.yMenu = e.pageY;
        this.menu = true;
      },
    },
    created() {
      this.fetchCategoriaPadre();
    },
  };
  </script>
  
  <style lang="scss" scoped>
  .empresa-contabilidad-container {
    padding: 20px;
  
    .status-section {
      margin-bottom: 20px;
    }
  
    .actions-section {
      margin: 20px 0;
      gap: 10px;
    }
  
    v-alert {
      margin-bottom: 15px;
    }
  }
  </style>
  